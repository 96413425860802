.sublime-uploader {
  width: auto;
  min-width: 300px;
  min-height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-style: dashed;
  border-width: 2px;
  border-color: #34bfa3;
  padding: 15px;
  margin: 15px;

  .upload-info-text {
    opacity: 0.5;
  }

  .sublime-uploader-content {
    width: 100%;
    height: 100%;

    .sublime-uploader-item {
      max-width: 80%;
      margin: 5px 0;

      span {}

      img {
        margin-left: 10px;
        margin-right: 10px;
      }

      p {}

      .sublime-uploader-delete {
        color: red;
        cursor: pointer;
        font-size: 20px;
      }

      &:hover {
        color: #34bfa3;
      }
    }
  }
}

.sublime-uploader-overred {
  transform: scale(1.2);
}
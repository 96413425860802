.sublime-tuto {
  margin: 15px 0;
  padding: 15px;
}

.tuto-line {
  .sublime-and-or-container .sublime-and-or {
    margin: 0
  }

  .sublime-and-or-container .bottom-arrow {
    margin-left: 23px;
  }
}
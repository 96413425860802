.sublime-switch {
  margin-right: 10px;
}

.custom-control-label:before {
  background-color: white;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: rgba(52, 191, 163, 1);
}
@import '../../ui-kit/assets/styles/variables.scss';

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$primary: $mainGreen;
$secondary: $mainSilver;
$success: $mainGreen;
$info: $mainSilver;
$warning: $mainOrange;
$danger: $mainRed;
$light: $lightGrey;
$dark: $mainBlack;

.form-group {
  label {
    margin-bottom: 15px;
  }
}

.row {
  margin-right: 15px !important;
}

.toast,
.modal,
#menu-element-modal,
#sublime-configs-uploader,
#sublime-combinaisons-creator {
  .row {
    margin-right: -15px !important;
  }
}

@media (max-width: 576px) {
  .stats-container,
  .sublime-menu-configurator {
    .row {
      margin-right: -15px !important;
    }
  }

  .sublime-menu-configurator {
    .collapse {
      padding-left: 0 !important;
    }

    .col-12 {
      padding: 0 !important;
    }
  }
}

@import '~bootstrap/scss/bootstrap';

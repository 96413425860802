.sublime-and-or-container {
  margin-left: -5px;
  margin-bottom: 10px;
  margin-top: 10px;

  .sublime-and-or {
    margin-left: -50px;
    ;

    .react-switch-bg {
      padding-left: 10px;
    }
  }

  .bottom-arrow {
    margin-left: -30px;
  }
}
div.sublime-tree-locked-container {
  height: 80vh;
  overflow: scroll;
  padding: 15px;
}

div.sublime-tree {
  margin-left: 35px;
  padding-left: 15px;
  border-left: 1px solid;
}

div.sublime-list {
  margin-bottom: 10px;
  white-space: nowrap;

  span.left-list-line-leaf {
    display: block;
    width: 15px;
    height: 1px;
    margin-left: -15px;
    background-color: black;
  }

  span.left-list-line {
    display: block;
    width: 15px;
    height: 1px;
    margin-left: -15px;
    background-color: red;
  }

  li.sublime-list.leaf {}

  span.sublime-list-item {
    min-width: 350px;
    border: 1px solid;
    padding: 15px;
    background-color: white;
    box-shadow: 0 2px 2px -2px;

    span {
      p {
        font-size: 10px;
      }
    }
  }

  span.sublime-list-actions {}
}
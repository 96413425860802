$mainGreen: #34BFA3;
$mainGold: #BEA971;
$mainOrange: #c3a248;
$mainRed: #F21E5A;
$mainSilver: #5D5D5D;
$mainBlack: #000000;
$mainDisabled: #BABABA;

$white: white;
$green: $mainGreen;
$gold: $mainGold;
$orange: $mainOrange;
$red: $mainRed;
$silver: $mainSilver;
$lightGrey: $mainDisabled;
$grey: $mainSilver;
$darkGrey: #242529;
$black: $mainBlack;

:export {
  white: $white;
  green: $green;
  gold: $gold;
  orange: $orange;
  red: $red;
  silver: $silver;
  grey: $grey;
  black: $black;
}

// Font size
$input-font-size: 14px;
$title-font-size: 24px;
$label-font-size: 14px;
$cb-label-font-size: 12px;
$button-font-size: 16px;

// Font familly
$title-font: 'Arial';
$label-font: 'Arial';
$button-font: 'Arial';
@import '../../ui-kit/assets/styles/variables.scss';

.signin-container {
  .welcome-text {
    font-family: $title-font;
    font-size: $title-font-size;
    color: $white;
    margin: 20px 0px;
  }

  .form-fields {
    .signin-more {
      .sublime-checkbox-group {
        label {
          font-family: $label-font;
          font-size: $cb-label-font-size;
          color: $white;
        }
      }

      a {
        font-size: $cb-label-font-size;
      }
    }
  }

  .no-account {
    margin-top: 40px;

    span {
      color: $white;
    }
  }
}

.sublime-switch-content {
  opacity: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  display: none;
  transition: width 0.5s, height 0.5s, opacity 0.5s, visibility 0s;
}

.sublime-switch-content-active {
  opacity: 1;
  width: auto;
  height: auto;
  visibility: visible;
  display: block;
  transition: width 0.5s, height 0.5s, opacity 0.5s, visibility 0.5s;
}